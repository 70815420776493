<template>
  <header class="fixed top-0 left-0 w-full z-30">
    <div
      class="
        md:px-10
        px-5
        flex
        items-center
        justify-between
        transition-all
        duration-300
      "
      :class="
        active_bg ? 'bg-black md:py-3 py-2' : 'bg-transparent md:py-8 py-6'
      "
    >
      <router-link to="/">
        <img
          class="transition-all duration-300"
          :class="active_bg ? 'md:w-28 w-24' : 'md:w-40 w-32'"
          src="/img/logo.png"
        />
      </router-link>

      <div class="flex items-center">
        <a
          @mouseenter="hover_btn = 'home'"
          @mouseleave="hover_btn = ''"
          href="https://www.bullking-exhaust.com/"
          target="_blank"
          class="
            text-white text-sm
            px-2
            py-2
            flex
            items-center
            transition-all
            duration-300
          "
          :class="hover_btn == 'home' ? 'text-red-700' : ''"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 mr-2"
            viewBox="0 0 576 512"
          >
            <path
              class="transition-all duration-300"
              :class="hover_btn == 'home' ? 'fill-red-700' : 'fill-white'"
              d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"
            />
          </svg>
          <span class="text-xs">返回官方網站</span>
        </a>
        <span class="w-[1px] h-5 bg-zinc-700 block md:mx-4 mx-2"></span>
        <a
          @mouseenter="hover_btn = 'facebook'"
          @mouseleave="hover_btn = ''"
          href="https://www.facebook.com/YongXinExhaust"
          target="_blank"
          class="
            text-white text-xs
            px-2
            py-2
            w-auto
            flex
            items-center
            font-black
            transition-all
            duration-300
          "
          :class="hover_btn == 'facebook' ? 'text-sky-500' : ''"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 md:mr-2"
            viewBox="0 0 448 512"
            fill="current"
          >
            <path
              class="transition-all duration-300"
              :class="hover_btn == 'facebook' ? 'fill-sky-500' : 'fill-white'"
              d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
            />
          </svg>
          <span class="md:block hidden">FACEBOOK</span>
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      hover_btn: "",
      active_bg: false,
    };
  },
  mounted() {
    if (window.scrollY > 200 || window.innerWidth <= 768) {
      this.active_bg = true;
    } else {
      this.active_bg = false;
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200 || window.innerWidth <= 768) {
        this.active_bg = true;
      } else {
        this.active_bg = false;
      }
    });
  },
};
</script>