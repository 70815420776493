import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: 0,
    page_image_loaded: false
  },
  getters: {},
  mutations: {
    SetLoading(state, action) {
      state.loading += action
    },
    SetImageLoaded(state, action) {
      state.page_image_loaded = action
    }
  },
  actions: {},
  modules: {}
})