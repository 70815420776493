var default_meta = {
    title: "義義聯名活動",
    content: "『 不是真的不會玩，是你沒有牛王管 』<br />義哥身為一個正港的8+9，一定要改最屌的牛王排氣管，展現台中8+9的氣質，呼嘯而過的排氣聲浪，停在紅綠燈前眾人的目光都在與牛王聯名的防燙蓋上，自信的嘴角一定是上揚的，綠燈的起步，讓路人留下羨慕的口水....你跟上義哥的後尾燈了嗎？還不立刻改起來～",
    image: "/img/share/event.jpg"
}

export function GetMetaData(title, content, image) {
    title = title == '' ? default_meta.title : title
    return {
        title: title + "｜牛王排氣管｜BullKing Exhaust",
        meta: [{
                property: "og:description",
                content: content == '' ? default_meta.content : content,
                vmid: "og:description",
            },
            {
                property: "twitter:description",
                content: content == '' ? default_meta.content : content,
                vmid: "twitter:description",
            },
            {
                property: "description",
                content: content == '' ? default_meta.content : content,
                vmid: "description",
            },
            {
                property: "og:image",
                content: image == '' ? default_meta.image : image,
                vmid: "og:image",
            },
            {
                property: "apple-mobile-web-app-title",
                content: title + "｜牛王排氣管｜BullKing Exhaust",
                vmid: "apple-mobile-web-app-title",
            },
            {
                property: "application-name",
                content: title + "｜牛王排氣管｜BullKing Exhaust",
                vmid: "application-name",
            },
            {
                property: "og:site_name",
                content: "官方網站｜牛王排氣管｜BullKing Exhaust",
                vmid: "og:site_name",
            },
            {
                property: "og:type",
                content: "website",
                vmid: "og:type",
            },
            {
                property: "og:locale",
                content: "zh_tw",
                vmid: "og:locale",
            },
        ],
    }
}