<template>
  <section id="HomeFeatures" class="relative">
    <div class="marquee_left">
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>
      <div class="whitespace-nowrap marquee_box">
        <p
          class="
            mr-5
            font-black
            md:text-4xl
            text-xl
            leading-none
            mb-2
            text-red-700
            inline-block
          "
          v-for="item in 50"
          :key="`text_${item}`"
        >
          BULLKING x FF SPECIAL COLLABORATION
        </p>
      </div>
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>
    </div>
    <div class="w-full max-w-screen-xl mx-auto xl:px-0 px-10">
      <!-- <CountDown /> -->

      <img
        class="w-full main_cover transform-gpu"
        src="/img/home_features/DSC04623.jpg"
      />
      <div class="mt-10 relative">
        <div
          class="
            flex
            md:flex-row
            flex-col
            md:items-center md:mb-5
            mb-10
            relative
            z-10
            card
          "
          v-for="item in cards"
          :key="item.title"
        >
          <div class="md:w-1/3 md:mb-0 mb-5 w-full image_box transform-gpu">
            <img :src="item.image" class="w-full" />
          </div>
          <div class="md:w-2/3 w-full md:pl-10">
            <h4 class="text-white font-bold text-xl title">{{ item.title }}</h4>
            <hr class="border-red-700 my-3 header_line" />
            <div class="text-white text-sm content" v-html="item.content"></div>
          </div>
        </div>

        <span
          class="
            absolute
            z-0
            top-1/2
            left-1/2
            -translate-x-1/2 -translate-y-1/2
            transform-gpu
            block
            w-60
            h-60
            bg-red-700
            blur-[150px]
          "
        ></span>
      </div>
    </div>

    <div class="marquee_right">
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>
      <div class="whitespace-nowrap marquee_box">
        <p
          class="
            mr-5
            font-black
            md:text-4xl
            text-xl
            leading-none
            mb-2
            text-red-700
            inline-block
          "
          v-for="item in 50"
          :key="`text_${item}`"
        >
          BULLKING x FF SPECIAL COLLABORATION
        </p>
      </div>
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>
    </div>
  </section>
</template>

<script>
// import CountDown from "@/components/CountDown.vue";
import {
  MarqueeLeftGsap,
  MarqueeRightGsap,
  FeaturesGsap,
} from "@/gsap/home/main_product";
export default {
  name: "HomeFeatures",
  components: {
    // CountDown,
  },
  data() {
    return {
      cards: [
        {
          title: "專業的排氣管製造技術",
          content:
            "這次義義與排氣管界知名的廠商「Bullking牛王競技部品」進行合作，聯合推出聯名款排氣管，不僅是噪音/馬力提升/扭力提升都能滿足你的需求，而且做工一點都不馬虎，每一個焊接點都是排氣管工廠師傅的結晶，讓你闖蕩江湖絕對不可或缺的一支「煙當管」，義哥真心推薦。",
          image: "/img/home_features/DSC04645.jpg",
        },
        {
          title: "細節處藏著魔鬼",
          content:
            "「Bullking牛王排氣管」不僅擁有自己的排氣管工廠，更是擁有獨立的設計開發部門，所有的排氣管製造與開發都可一條龍處理，在細節處都能感受到牛王排氣管的用心，每一支排氣管均配有獨立的編號，可將它視為「身分證」，也讓每一支義義聯名款排氣管都變得更「獨一無二」",
          image: "/img/home_features/DSC04648.jpg",
        },
        {
          title: "義哥的防燙蓋雷雕有夠帥啦",
          content:
            "「Bullking牛王排氣管」這此與義哥合作，直接卯足了勁，請設計開發部門設計了義哥專屬的防燙蓋雷雕式樣，只有此網站購買才買得到喔！！，細節處可是將義哥帥氣的臉龐繪製的唯妙唯肖，讓你騎乘於道路上，就像是伸展台般耀眼，停紅燈最矚目的就會是你。",
          image: "/img/home_features/DSC04646.jpg",
        },
      ],
    };
  },
  watch: {
    page_image_loaded() {
      let marquee_left = document.querySelector("#HomeFeatures .marquee_left");
      let marquee_right = document.querySelector(
        "#HomeFeatures .marquee_right"
      );
      let page = document.querySelector("#HomeFeatures");
      if (this.page_image_loaded) {
        MarqueeLeftGsap(marquee_left);
        MarqueeRightGsap(marquee_right);
        FeaturesGsap(page);
      }
    },
  },
  mounted() {
    let marquee_left = document.querySelector("#HomeFeatures .marquee_left");
    let marquee_right = document.querySelector("#HomeFeatures .marquee_right");
    let page = document.querySelector("#HomeFeatures");
    if (this.page_image_loaded) {
      MarqueeLeftGsap(marquee_left);
      MarqueeRightGsap(marquee_right);
      FeaturesGsap(page);
    }
  },
};
</script>
