<template>
  <section
    ref="HomeMainProduct"
    id="HomeMainProduct"
    class="relative md:py-60 sm:py-32 py-20"
  >
    <div class="w-full max-w-4xl mx-auto relative">
      <img
        class="w-full block relative z-10 product_image"
        src="/img/home_main_product.png"
      />
      <span
        class="
          absolute
          z-0
          top-1/2
          left-1/2
          transform-gpu
          -translate-x-1/2 -translate-y-1/2
          block
          sm:w-60
          w-40
          sm:h-60
          h-40
          bg-red-500
          sm:blur-[150px]
          blur-[100px]
        "
      ></span>
    </div>
    <div class="relative z-10 mt-20">
      <h3
        class="
          text-white
          md:text-4xl
          text-3xl
          font-black
          text-center
          title
          transform-gpu
        "
      >
        牛王排氣管義義聯名款
      </h3>
      <h4 class="text-red-700 md:text-lg font-black mt-1 text-center sub_title">
        NEW COLLABORATION PRODUCT
      </h4>
      <div
        class="
          text-center
          md:text-base
          text-sm text-white
          mt-8
          max-w-screen-md
          mx-auto
          content
          md:leading-relaxed
          leading-relaxed
        "
      >
        <p>
          『 不是真的不會玩，是你沒有牛王管 』<br />義哥身為一個正港的8+9，一定要改最屌的牛王排氣管，展現台中8+9的氣質，呼嘯而過的排氣聲浪，停在紅綠燈前眾人的目光都在與牛王聯名的防燙蓋上，自信的嘴角一定是上揚的，綠燈的起步，讓路人留下羨慕的口水....你跟上義哥的後尾燈了嗎？還不立刻改起來～
        </p>
      </div>
    </div>

    <div ref="feature_card" class="flex flex-wrap items-center w-full mt-32">
      <div class="md:w-1/2 w-full">
        <img
          class="w-full block product_image"
          src="/img/home_main_product/des-3.jpeg"
        />
      </div>
      <div class="md:w-1/2 w-full text-white md:mt-0 mt-10 md:pl-10">
        <h3 class="text-3xl font-bold title">環保署認證鐵牌</h3>
        <h4 class="text-red-700 font-black sub_title">EPA CERTIFICATION</h4>
        <hr class="border-red-700 mt-5 header_line" />
        <div class="content">
          <p class="mt-6">
            通過環保署車測檢驗，只要您是原廠引擎搭載此設定排氣管噪音分貝絕對符合政府規定，桶身上會有環保署認可鐵牌可供警方攔查時比對。
          </p>
          <p class="mt-10 mb-3">
            <a
              class="text-red-700 underline"
              href="https://www.artc.org.tw/carmode/"
              target="_blank"
              >環保署符合噪音管制標準車輛清冊連結</a
            >
          </p>
          <p>
            <a
              class="text-red-700 underline"
              href="https://www.artc.org.tw/carmode/"
              target="_blank"
              >新北市政府環境保護局噪音檢測合格排氣管名冊</a
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { MainProductGsap, EventGsap } from "@/gsap/home/main_product";
export default {
  name: "HomeMainProduct",
  watch: {
    page_image_loaded() {
      if (this.page_image_loaded) {
        MainProductGsap(this.$refs.HomeMainProduct);
        EventGsap(this.$refs.feature_card);
      }
    },
  },
  mounted() {
    if (this.page_image_loaded) {
      MainProductGsap(this.$refs.HomeMainProduct);
      EventGsap(this.$refs.feature_card);
    }
  },
};
</script>