import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtm from '@gtm-support/vue2-gtm';
import VueMeta from 'vue-meta'


import image_loader from "@/gsap/image_loaded"
Vue.mixin(image_loader)

Vue.use(VueGtm, {
  id: 'GTM-M4GXX8P',
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
})


Vue.use(VueMeta, {
  refreshOnceOnNavigation: false
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')