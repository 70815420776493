import {
    gsap
} from "@/gsap/gsap_loader"

export function MainProductGsap(el) {
    let image = el.querySelector(".product_image")
    let title = el.querySelector(".title")
    let sub_title = el.querySelector(".sub_title")
    let content = el.querySelector(".content")

    let main_product_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })

    main_product_tl.fromTo(image, {
        opacity: 0,
        scale: 1.5,
        transformOrigin: "center",
        y: "100px"
    }, {
        opacity: 1,
        scale: 1,
        transformOrigin: "center",
        y: "0px"
    }, 0)

    main_product_tl.fromTo(title, {
        opacity: 0,
        webkitFilter: "blur(100px)"
    }, {
        opacity: 1,
        webkitFilter: "blur(0px)",
        delay: .5
    }, 0)


    main_product_tl.fromTo(sub_title, {
        opacity: 0,
    }, {
        opacity: 1,
        delay: .6
    }, 0)



    main_product_tl.fromTo(content, {
        opacity: 0,
        y: "30%"
    }, {
        opacity: 1,
        y: "0%",
        delay: .8
    }, 0)

}

export function GalleryGsap(el) {

    let title = el.querySelector(".title")
    let sub_title = el.querySelector(".sub_title")
    let header_line = el.querySelector(".header_line")
    let content = el.querySelector(".content")

    let gallery_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })

    gallery_tl.fromTo(title, {
        opacity: 0,
        webkitFilter: "blur(100px)"
    }, {
        opacity: 1,
        webkitFilter: "blur(0px)",
    }, 0)

    gallery_tl.fromTo(sub_title, {
        opacity: 0,
    }, {
        opacity: 1,
        delay: .3
    }, 0)


    gallery_tl.fromTo(header_line, {
        scaleX: 0,
        transformOrigin: "left"
    }, {
        scaleX: 1,
        transformOrigin: "left",
        delay: .4
    }, 0)


    gallery_tl.fromTo(content, {
        opacity: 0,
        y: "10%"
    }, {
        opacity: 1,
        y: "0%",
        delay: .6
    }, 0)

}

export function MarqueeLeftGsap(el) {
    let marquee_box = el.querySelector(".marquee_box")
    let flash_line = el.querySelectorAll(".flash_line")
    let word_width = el.querySelectorAll("p")[0].offsetWidth
    let flash_tl = gsap.timeline({
        repeat: -1
    })

    gsap.fromTo(marquee_box, {
        x: "0px"
    }, {
        x: (word_width * -3) + "px",
        duration: 40,
        ease: "linear",
        repeat: -1
    })

    flash_tl.fromTo(flash_line, {
        opacity: .5
    }, {
        opacity: .3,
        ease: "none",
        duration: .5,
    }, 0)
    flash_tl.fromTo(flash_line, {
        opacity: .3
    }, {
        opacity: .5,
        delay: 0,
        ease: "none",
        duration: .5,
    }, 1)



}

export function MarqueeRightGsap(el) {
    let marquee_box = el.querySelector(".marquee_box")
    let flash_line = el.querySelectorAll(".flash_line")
    let word_width = el.querySelectorAll("p")[0].offsetWidth
    let flash_tl = gsap.timeline({
        repeat: -1
    })

    gsap.fromTo(marquee_box, {
        x: (word_width * -3) + "px",
    }, {
        x: "0px",
        duration: 40,
        ease: "linear",
        repeat: -1
    })

    flash_tl.fromTo(flash_line, {
        opacity: .5
    }, {
        opacity: .3,
        ease: "none",
        duration: .5,
    }, 0)
    flash_tl.fromTo(flash_line, {
        opacity: .3
    }, {
        opacity: .5,
        delay: 0,
        ease: "none",
        duration: .5,
    }, 1)



}

export function EventGsap(el) {
    let title = el.querySelector(".title")
    let sub_title = el.querySelector(".sub_title")
    let header_line = el.querySelector(".header_line")
    let content = el.querySelector(".content")
    let product_image = el.querySelector(".product_image")

    let event_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })
    event_tl.fromTo(product_image, {
        opacity: 0,
        webkitFilter: "blur(100px)"
    }, {
        opacity: 1,
        webkitFilter: "blur(0px)"
    }, 0)

    event_tl.fromTo(title, {
        opacity: 0,
        y: "10%"
    }, {
        opacity: 1,
        y: "0%",
        delay: .5
    }, 0)

    event_tl.fromTo(sub_title, {
        opacity: 0,
    }, {
        opacity: 1,
        delay: .6
    }, 0)


    event_tl.fromTo(header_line, {
        scaleX: 0,
        transformOrigin: "left"
    }, {
        scaleX: 1,
        transformOrigin: "left",
        delay: .6
    }, 0)


    event_tl.fromTo(content, {
        opacity: 0,
        y: "10%"
    }, {
        opacity: 1,
        y: "0%",
        delay: .8
    }, 0)
}

export function FeaturesGsap(el) {
    let cards = el.querySelectorAll(".card")
    let main_cover = el.querySelector(".main_cover")

    let feature_tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            toggleActions: "restart play play reverse"
        }
    })
    feature_tl.fromTo(main_cover, {
        opacity: 0,
        webkitFilter: "blur(100px)"
    }, {
        opacity: 1,
        webkitFilter: "blur(0px)"
    }, 0)


    cards.forEach(card => {
        let image = card.querySelector(".image_box")
        let title = card.querySelector(".title")
        let header_line = card.querySelector(".header_line")
        let content = card.querySelector(".content")
        let card_tl = gsap.timeline({
            scrollTrigger: {
                trigger: card,
                start: "top center",
                toggleActions: "restart play play reverse"
            }
        })
        card_tl.fromTo(image, {
            opacity: 0,
            webkitFilter: "blur(100px)"
        }, {
            opacity: 1,
            webkitFilter: "blur(0px)"
        }, 0)
        card_tl.fromTo(title, {
            x: "-30px",
            opacity: 0
        }, {
            x: "0px",
            opacity: 1
        }, 0)
        card_tl.fromTo(header_line, {
            scaleX: 0,
            transformOrigin: "left"
        }, {
            scaleX: 1,
            transformOrigin: "left"
        }, 0)
        card_tl.fromTo(content, {
            y: "30%",
            opacity: 0
        }, {
            y: "0%",
            opacity: 1
        }, 0)
    })
}