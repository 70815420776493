import {
    get,
    post_form,
    zero_card_post
} from "@/api/request"

export function GetAllData() {
    return get("customize/get")

}


export function CreateTrade(product_data, scooter, user_data, total_price) {
    let form_data = new FormData();
    form_data.append("user_data", JSON.stringify(user_data))
    form_data.append("product_data", JSON.stringify(product_data))
    form_data.append("scooter", scooter)
    form_data.append("total_price", total_price)
    return post_form("customize/create", form_data)
}

export function CreateZeroCardTrade(header_info, trade_info) {
    return zero_card_post("https://api.chaileaseholding.com/api_zero_card/payments/reserve_ec", trade_info, header_info[Object.keys(header_info)[1]], header_info[Object.keys(header_info)[0]])
}