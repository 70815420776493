<template>
  <section
    id="HomeForm"
    class="relative w-full max-w-screen-xl px-10 py-32 mx-auto xl:px-0"
  >
    <h3 class="text-4xl font-bold text-white">訂購排氣管</h3>
    <h4 class="text-xl font-black text-red-700">BUY EXHAUST</h4>
    <hr class="mt-5 border-red-700" />
    <div v-if="scooters.length != 0" class="mt-10">
      <!-- 廠牌 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">車款廠牌</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">BRANDS</p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="active_brand"
            >
              <option value="" class="bg-black">請選擇車款廠牌</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in Object.keys(scooters)"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <p v-if="GetError('brand')" class="text-sm text-red-700">
            請選擇車款廠牌
          </p>
        </div>
      </div>
      <!-- 車款 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">車款型號</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">SCOOTER</p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="scooter_data"
            >
              <option :value="null" class="bg-black">請選擇車款</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in scooters[active_brand]"
                :key="item.title"
              >
                {{ item.title }}
              </option>
            </select>
          </div>
          <p v-if="GetError('scooter')" class="text-sm text-red-700">
            請選擇車款
          </p>
        </div>
      </div>
      <!-- 排氣管型號 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">排氣管型號</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">EXHAUST</p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="product_data"
            >
              <option :value="null" class="bg-black">請選擇排氣管</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in avaliable_products"
                :key="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <p v-if="GetError('product')" class="text-sm text-red-700">
            請選擇排氣管型號
          </p>
        </div>
      </div>

      <!-- 排氣管前段設定 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">前段設定</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">
            FRONT OPTION
          </p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="active_options.front_option"
            >
              <option :value="null" class="bg-black">請選擇設定</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in avaliable_front_options"
                :key="item.name"
              >
                {{ item.name
                }}{{ item.price != 0 ? `(+NT$${item.price})` : "" }}
              </option>
            </select>
          </div>
          <p v-if="GetError('front_option')" class="text-sm text-red-700">
            請選擇前段設定
          </p>
        </div>
      </div>

      <!-- 排氣管內部設定 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">內部設定</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">
            POWER OPTION
          </p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="active_options.power_option"
            >
              <option :value="null" class="bg-black">請選擇設定</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in avaliable_power_options"
                :key="item.name"
              >
                {{ item.name
                }}{{ item.price != 0 ? `(+NT$${item.price})` : "" }}
              </option>
            </select>
          </div>
          <p v-if="GetError('power_option')" class="text-sm text-red-700">
            請選擇內部設定
          </p>
        </div>
      </div>

      <!-- 排氣管防燙蓋設定 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">防燙蓋設定</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">CASE OPTION</p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="active_options.case_option"
            >
              <option :value="null" class="bg-black">請選擇設定</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in avaliable_case_options"
                :key="item.name"
              >
                {{ item.name
                }}{{ item.price != 0 ? `(+NT$${item.price})` : "" }}
              </option>
            </select>
          </div>
          <p v-if="GetError('case_option')" class="text-sm text-red-700">
            請選擇防燙蓋設定
          </p>
        </div>
      </div>

      <!-- 排氣管腳架設定 -->
      <div
        class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10"
        v-if="avaliable_hanger_options.length > 0"
      >
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">腳架設定</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">
            HANGER OPTION
          </p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="active_options.hanger_option"
            >
              <option :value="null" class="bg-black">請選擇設定</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in avaliable_hanger_options"
                :key="item.name"
              >
                {{ item.name
                }}{{ item.price != 0 ? `(+NT$${item.price})` : "" }}
              </option>
            </select>
          </div>
          <p v-if="GetError('hanger_option')" class="text-sm text-red-700">
            請選擇腳架設定
          </p>
        </div>
      </div>

      <!-- 排氣管束環設定 -->
      <div
        class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10"
        v-if="avaliable_ring_options.length > 0"
      >
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">束環設定</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">RING OPTION</p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="active_options.ring_option"
            >
              <option :value="null" class="bg-black">請選擇設定</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in avaliable_ring_options"
                :key="item.name"
              >
                {{ item.name
                }}{{ item.price != 0 ? `(+NT$${item.price})` : "" }}
              </option>
            </select>
          </div>
          <p v-if="GetError('ring_option')" class="text-sm text-red-700">
            請選擇束環設定
          </p>
        </div>
      </div>

      <hr class="mb-5 border-red-700 md:mb-10" />

      <!-- 姓名 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">訂購人姓名</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">NAME</p>
        </div>
        <div class="max-w-full w-96">
          <input
            name="name"
            v-model="user_data.name"
            type="text"
            class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
          />
          <p v-if="GetError('name')" class="text-sm text-red-700">
            請輸入正確的中文姓名
          </p>
        </div>
      </div>
      <!-- 電話 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">連絡電話</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">PHONE</p>
        </div>
        <div class="max-w-full w-96">
          <input
            name="phone"
            v-model="user_data.phone"
            type="tel"
            class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
          />
          <p v-if="GetError('phone')" class="text-sm text-red-700">
            請輸入正確的電話號碼
          </p>
        </div>
      </div>

      <!-- EMAIL -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">電子信箱</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">EMAIL</p>
        </div>
        <div class="max-w-full w-96">
          <input
            name="email"
            v-model="user_data.email"
            type="email"
            class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
          />
          <p v-if="GetError('email')" class="text-sm text-red-700">
            請輸入正確的電子郵件
          </p>
        </div>
      </div>

      <!-- 地址 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">收件地址</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">ADDRESS</p>
        </div>
        <div class="max-w-full w-96">
          <input
            name="address"
            v-model="user_data.address"
            type="text"
            class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
          />
          <p v-if="GetError('address')" class="text-sm text-red-700">
            請輸入正確的地址
          </p>
        </div>
      </div>

      <!-- 備註 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">訂單備註</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">COMMENT</p>
        </div>
        <div class="max-w-full w-96">
          <textarea
            name="comment"
            v-model="user_data.comment"
            type="text"
            class="w-full min-h-[150px] appearance-none border border-red-700 px-5 py-3 bg-transparent text-white focus:outline-none"
          ></textarea>
        </div>
      </div>

      <hr class="mb-5 border-red-700 md:mb-10" />
      <!-- 付款方式 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">付款方式</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">PAYMENT</p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="user_data.payment"
            >
              <option :value="null" class="bg-black">請選擇付款方式</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in payment_list"
                :key="item.title"
              >
                {{ item.title }}
              </option>
            </select>
          </div>
          <p v-if="GetError('payment')" class="text-sm text-red-700">
            請選擇付款方式
          </p>
        </div>
      </div>
      <!-- 分期付款 -->
      <div
        class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10"
        v-if="user_data.payment != null && user_data.payment.installment"
      >
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">分期付款</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">INSTALLMENT</p>
        </div>
        <div class="max-w-full w-96">
          <div class="relative">
            <span
              class="absolute text-red-700 transform -translate-y-1/2 pointer-events-none material-symbols-outlined top-1/2 right-3"
            >
              arrow_drop_down
            </span>
            <select
              class="w-full px-5 py-3 text-white bg-transparent border border-red-700 appearance-none focus:outline-none"
              v-model="user_data.installment"
            >
              <option :value="0" class="bg-black">請選擇分期期數</option>
              <option
                :value="item"
                class="bg-black"
                v-for="item in Object.keys(user_data.payment.installment_list)"
                :key="user_data.payment.title + item"
              >
                {{ item }}期，(+NT${{
                  Math.ceil(
                    user_data.payment.installment_list[item] * total_price
                  )
                }})
              </option>
            </select>
          </div>
          <p v-if="GetError('installment')" class="text-sm text-red-700">
            請選擇分期期數
          </p>
        </div>
      </div>

      <!-- 總金額 -->
      <div class="flex flex-col mb-5 md:flex-row md:items-center md:mb-10">
        <div class="flex items-end mb-3 w-60 md:block md:mb-0">
          <h4 class="text-xl font-bold text-white">總金額</h4>
          <p class="ml-2 text-sm font-bold text-red-700 md:ml-0">TOTAL PRICE</p>
        </div>
        <div class="max-w-full w-96">
          <h4 class="text-3xl font-black text-white">
            NT${{ (total_price + installment_percent) | currency }}
          </h4>
        </div>
      </div>

      <hr class="mb-5 border-red-700 md:mb-10" />
      <div class="flex items-center justify-center">
        <button
          @click="Validate"
          :class="
            order_create_avaliable
              ? 'bg-red-700 hover:cursor-pointer'
              : 'bg-zinc-900 hover:cursor-wait'
          "
          class="max-w-full px-10 py-4 text-white w-96"
        >
          {{ order_create_avaliable ? "送出訂單" : "請稍後" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { GetAllData, CreateTrade, CreateZeroCardTrade } from "@/api/customize";
import {
  validEmail,
  validPhone,
  validName,
  validAddress,
} from "@/common/validate";
export default {
  name: "HomeForm",
  data() {
    return {
      case_options: [],
      front_options: [],
      hanger_options: [],
      power_options: [],
      products: [],
      ring_options: [],
      scooters: [],
      active_brand: "",
      product_data: null,
      scooter_data: null,
      active_options: {
        front_option: null,
        power_option: null,
        case_option: null,
        ring_option: null,
        hanger_option: null,
      },
      user_data: {
        name: "",
        phone: "",
        address: "",
        comment: "",
        email: "",
        installment: 0,
        payment: null,
        trade_source: "義義聯名網站",
      },
      payment_list: [
        {
          id: 1,
          title: "零卡分期(分期零利率)",
          type: "zero_card",
          installment: true,
          installment_list: {
            3: 0,
            6: 0,
            12: 0,
            18: 0,
            24: 0,
          },
        },
        {
          id: 2,
          title: "信用卡付款",
          type: "credit",
          installment: false,
        },
        {
          id: 1,
          title: "信用卡分期付款",
          type: "credit_installment",
          installment: true,
          installment_list: {
            3: 0.03,
            6: 0.035,
            12: 0.07,
            18: 0.09,
            24: 0.12,
          },
        },
        {
          id: 1,
          title: "ATM轉帳",
          type: "atm",
          installment: false,
        },
        {
          id: 1,
          title: "超商代碼繳費",
          type: "cvs",
          installment: false,
        },
        {
          id: 1,
          title: "貨到付款",
          type: "cod",
          installment: false,
        },
      ],
      errors: [],
      order_create_avaliable: true,
    };
  },
  methods: {
    GetProductData() {
      GetAllData().then((res) => {
        console.log(res);
        this.case_options = res.data.case_options.data;
        this.case_options.forEach((item, item_index) => {
          item.id == 6 ? (this.case_options[item_index].price = 0) : "";
        });

        this.front_options = res.data.front_options.data;
        this.hanger_options = res.data.hanger_options.data;
        this.power_options = res.data.power_options.data;
        this.products = res.data.products.data;
        this.ring_options = res.data.ring_options.data;
        this.scooters = res.data.scooters.data;
      });
    },
    ResetDefaultOption() {
      this.active_options = {
        front_option: null,
        power_option: null,
        case_option: null,
        ring_option: null,
        hanger_option: null,
      };
    },
    GetError(key) {
      return this.errors.indexOf(key) != -1;
    },
    Validate() {
      this.errors = [];
      //   商品
      this.active_brand == "" ? this.errors.push("brand") : "";
      this.scooter_data == null ? this.errors.push("scooter") : "";
      this.product_data == null ? this.errors.push("product") : "";
      this.active_options.front_option == null
        ? this.errors.push("front_option")
        : "";
      this.active_options.power_option == null
        ? this.errors.push("power_option")
        : "";
      this.active_options.case_option == null
        ? this.errors.push("case_option")
        : "";
      this.active_options.ring_option == null &&
      this.avaliable_ring_options.length > 0
        ? this.errors.push("ring_option")
        : "";
      this.active_options.hanger_option == null &&
      this.avaliable_hanger_options.length > 0
        ? this.errors.push("hanger_option")
        : "";
      // 個人資料
      !validEmail(this.user_data.email) ? this.errors.push("email") : "";
      !validPhone(this.user_data.phone) ? this.errors.push("phone") : "";
      !validName(this.user_data.name) ? this.errors.push("name") : "";
      !validAddress(this.user_data.address) ? this.errors.push("address") : "";
      if (this.errors.length <= 0) {
        this.SendTradeOrder();
      } else {
        let el_offset_top = document.querySelector("#HomeForm").offsetTop;
        window.scrollTo({
          top: el_offset_top,
          behavior: "smooth",
        });
      }
    },
    SendTradeOrder() {
      let product_data = {
        name: this.product_data.name,
        options: "",
      };
      product_data.options +=
        "內部設定:" + this.active_options.power_option.name + ";";
      product_data.options +=
        "前段設定:" + this.active_options.front_option.name + ";";
      product_data.options +=
        "防燙蓋設定:" + this.active_options.case_option.name;
      if (this.active_options.ring_option != null) {
        product_data.options +=
          ";束環設定:" + this.active_options.ring_option.name;
      }
      if (this.active_options.hanger_option != null) {
        product_data.options +=
          ";腳架設定:" + this.active_options.hanger_option.name + ";";
      }
      let scooter = this.scooter_data.title;
      let user_data = Object.assign({}, this.user_data);
      if (user_data.installment != 0) {
        user_data.installment_percent =
          user_data.payment.installment_list[user_data.installment];
      }
      user_data.payment = user_data.payment.title;
      user_data.trade_source = "義義聯名網站";
      let total_price = this.total_price + this.installment_percent;

      if (this.order_create_avaliable) {
        this.order_create_avaliable = false;
        CreateTrade(product_data, scooter, user_data, total_price).then(
          (res) => {
            console.log(res);
            if (this.user_data.payment.title == "貨到付款") {
              this.$router.push("/order_create/" + res.data + "/cod");
            } else if (this.user_data.payment.title == "零卡分期(分期零利率)") {
              CreateZeroCardTrade(
                res.data.header_info,
                res.data.trade_info
              ).then((zero_res) => {
                location.replace(zero_res.info_reserve.payment_url_app);
              });
            } else {
              document
                .querySelector("body")
                .insertAdjacentHTML("afterend", res.data);
              document.querySelector("#Newebpay").submit();
            }
          }
        );
      }
    },
  },
  watch: {
    product_data() {
      if (this.product_data != null && this.scooter_data != null) {
        this.ResetDefaultOption();
      }
    },
    scooter_data() {
      if (this.product_data != null && this.scooter_data != null) {
        this.ResetDefaultOption();
      }
    },
    "user_data.payment"() {
      this.user_data.installment = 0;
    },
  },
  computed: {
    avaliable_products() {
      if (this.scooter_data != null) {
        return this.products.filter((item) => {
          if (
            !this.scooter_data.options[item.code] ||
            this.scooter_data.options[item.code].enable != false
          ) {
            return item;
          }
        });
      } else {
        return [];
      }
    },
    avaliable_front_options() {
      if (this.scooter_data != null && this.product_data != null) {
        let tmp_front_option = JSON.parse(JSON.stringify(this.front_options));
        //取得商品 前段設定
        let product_front_option = this.product_data.options.filter(
          (item) => item.type == "front_option"
        )[0];
        //取得車款 相關設定
        let scooter_option = this.scooter_data.options[this.product_data.code];

        product_front_option.option_list.forEach((product_option) => {
          tmp_front_option.forEach((item) => {
            if (item.id == product_option.id) {
              item.price = product_option.price;
            }
          });
        });
        if (scooter_option && scooter_option.front_option) {
          scooter_option.front_option.forEach((scooter_option) => {
            tmp_front_option.forEach((item) => {
              if (item.id == scooter_option.id) {
                item.price = scooter_option.price;
              }
            });
          });
        }
        return tmp_front_option.filter((item) => item.price != -1);
      } else {
        return [];
      }
    },
    avaliable_power_options() {
      if (this.scooter_data != null && this.product_data != null) {
        let tmp_power_option = JSON.parse(JSON.stringify(this.power_options));
        //取得商品 內部設定
        let product_power_option = this.product_data.options.filter(
          (item) => item.type == "power_option"
        )[0];
        //取得車款 相關設定
        let scooter_option = this.scooter_data.options[this.product_data.code];

        product_power_option.option_list.forEach((product_option) => {
          tmp_power_option.forEach((item) => {
            if (item.id == product_option.id) {
              item.price = product_option.price;
            }
          });
        });
        if (scooter_option && scooter_option.power_option) {
          scooter_option.power_option.forEach((scooter_option) => {
            tmp_power_option.forEach((item) => {
              if (item.id == scooter_option.id) {
                item.price = scooter_option.price;
              }
            });
          });
        }
        return tmp_power_option.filter((item) => item.price != -1);
      } else {
        return [];
      }
    },
    avaliable_case_options() {
      if (this.scooter_data != null && this.product_data != null) {
        let tmp_case_option = JSON.parse(JSON.stringify(this.case_options));
        //取得商品 防燙蓋設定
        let product_case_option = this.product_data.options.filter(
          (item) => item.type == "case_option"
        )[0];
        //取得車款 相關設定
        let scooter_option = this.scooter_data.options[this.product_data.code];

        product_case_option.option_list.forEach((product_option) => {
          tmp_case_option.forEach((item) => {
            if (item.id == product_option.id) {
              item.price = product_option.price;
            }
          });
        });
        if (scooter_option && scooter_option.case_option) {
          scooter_option.case_option.forEach((scooter_option) => {
            tmp_case_option.forEach((item) => {
              if (item.id == scooter_option.id) {
                item.price = scooter_option.price;
              }
            });
          });
        }
        return tmp_case_option.filter((item) => item.price != -1);
      } else {
        return [];
      }
    },
    avaliable_hanger_options() {
      if (this.scooter_data != null && this.product_data != null) {
        let option_exist =
          this.product_data.options.filter((item) => item.title == "腳架設定")
            .length > 0;
        if (option_exist) {
          let tmp_hanger_option = JSON.parse(
            JSON.stringify(this.hanger_options)
          );
          //取得商品 防燙蓋設定
          let product_hanger_option = this.product_data.options.filter(
            (item) => item.type == "hanger_option"
          );
          //取得車款 相關設定
          let scooter_option =
            this.scooter_data.options[this.product_data.code];

          if (product_hanger_option.length > 0) {
            product_hanger_option = product_hanger_option[0];
            product_hanger_option.option_list.forEach((product_option) => {
              tmp_hanger_option.forEach((item) => {
                if (item.id == product_option.id) {
                  item.price = product_option.price;
                }
              });
            });
          }

          if (scooter_option && scooter_option.hanger_option) {
            scooter_option.hanger_option.forEach((scooter_option) => {
              tmp_hanger_option.forEach((item) => {
                if (item.id == scooter_option.id) {
                  item.price = scooter_option.price;
                }
              });
            });
          }
          return tmp_hanger_option.filter((item) => item.price != -1);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    avaliable_ring_options() {
      if (this.scooter_data != null && this.product_data != null) {
        let option_exist =
          this.product_data.options.filter((item) => item.title == "束環設定")
            .length > 0;
        if (option_exist) {
          let tmp_ring_option = JSON.parse(JSON.stringify(this.ring_options));
          //取得商品 防燙蓋設定
          let product_ring_option = this.product_data.options.filter(
            (item) => item.type == "ring_option"
          );
          //取得車款 相關設定
          let scooter_option =
            this.scooter_data.options[this.product_data.code];

          if (product_ring_option.length > 0) {
            product_ring_option = product_ring_option[0];
            product_ring_option.option_list.forEach((product_option) => {
              tmp_ring_option.forEach((item) => {
                if (item.id == product_option.id) {
                  item.price = product_option.price;
                }
              });
            });
          }

          if (scooter_option && scooter_option.ring_option) {
            scooter_option.ring_option.forEach((scooter_option) => {
              tmp_ring_option.forEach((item) => {
                if (item.id == scooter_option.id) {
                  item.price = scooter_option.price;
                }
              });
            });
          }
          return tmp_ring_option.filter((item) => item.price != -1);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    total_price() {
      if (this.product_data != null) {
        let price = this.product_data.price;
        Object.keys(this.active_options).forEach((item) => {
          if (this.active_options[item] != null) {
            price += this.active_options[item].price;
          }
        });
        return price;
      } else {
        return 0;
      }
    },
    installment_percent() {
      if (this.user_data.payment != null && this.user_data.installment != 0) {
        let percent =
          this.user_data.payment.installment_list[this.user_data.installment];
        return Math.ceil(this.total_price * percent);
      } else {
        return 0;
      }
    },
  },
  filters: {
    currency(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  created() {
    this.GetProductData();
  },
};
</script>
