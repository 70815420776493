<template>
  <main id="HomePage" class="md:mt-0 mt-12">
    <button
      @click="ScrollToForm"
      :class="scroll_btn_show ? 'flex' : 'hidden'"
      class="scroll_to_buy"
    >
      直接<br />購買
    </button>
    <Cover :cover_data="cover_data" />
    <Features />
    <div class="w-full max-w-screen-xl xl:px-0 px-10 mx-auto relative">
      <Promote />
      <MainProduct />
    </div>
    <Event />
    <Warning />
    <Gallery :gallery="gallery" />
    <Form />
  </main>
</template>

<script>
// @ is an alias to /src
import Cover from "@/components/Home/Cover.vue";
import MainProduct from "@/components/Home/MainProduct.vue";
import Gallery from "@/components/Home/Gallery.vue";
import Event from "@/components/Home/Event.vue";
import Form from "@/components/Home/Form.vue";
import Features from "@/components/Home/Features.vue";
import Promote from "@/components/Home/Promote.vue";
import Warning from "@/components/Home/Warning.vue";
import { GetMetaData } from "@/common/meta";

export default {
  name: "HomeView",
  components: {
    Cover,
    MainProduct,
    Gallery,
    Event,
    Form,
    Features,
    Promote,
    Warning,
  },
  data() {
    return {
      meta_data: null,
      scroll_btn_show: true,
      cover_data: {
        pc_image: "/img/活動封面圖_3.jpg",
        mb_image: "/img/活動封面圖_3.jpg",
      },
      gallery: [
        {
          title: "BX1",
          type: "黑鐵排氣管",
          image: "/img/gallery/bx1.png",
        },
        {
          title: "BX2",
          type: "白鐵排氣管",
          image: "/img/gallery/bx2.png",
        },
        {
          title: "BX3",
          type: "合法認證白鐵排氣管",
          image: "/img/gallery/bx3.png",
        },
        {
          title: "BX7",
          type: "白鐵排氣管",
          image: "/img/gallery/bx7.png",
        },
        {
          title: "BK999",
          type: "白鐵排氣管",
          image: "/img/gallery/bk999.png",
        },
      ],
    };
  },
  methods: {
    ScrollToForm() {
      let el_offset_top = document.querySelector("#HomeForm").offsetTop;
      window.scrollTo({
        top: el_offset_top,
        behavior: "smooth",
      });
    },
    CheckScrollBtnShow() {
      let el_offset_top = document.querySelector("#HomeForm").offsetTop;
      if (window.scrollY + window.innerHeight > el_offset_top) {
        this.scroll_btn_show = false;
      } else {
        this.scroll_btn_show = true;
      }
    },
  },
  mounted() {
    this.meta_data = GetMetaData("", "", "");
    this.$nextTick(() => {
      this.LoadImage();
      window.prerenderReady = true;
    });
    this.CheckScrollBtnShow();
    window.addEventListener("scroll", () => {
      if (this.$route.name == "home") {
        this.CheckScrollBtnShow();
      }
    });
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
