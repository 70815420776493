<template>
  <section ref="HomeGallery" id="HomeGallery" class="relative pb-10 mt-20">
    <div class="w-full max-w-screen-xl mx-auto xl:px-0 px-10">
      <h3 class="text-white text-4xl font-black mr-2 mb-1 title transform-gpu">
        產品相簿
      </h3>
      <h4
        class="
          text-red-700 text-lg
          font-black
          leading-none
          md:mt-0
          mt-2
          sub_title
        "
      >
        GALLERY
      </h4>
      <hr class="mt-5 border-red-700 header_line" />
      <div class="flex items-stretch flex-wrap -mx-5 mt-5 content">
        <div
          class="lg:w-1/3 md:w-1/2 w-full px-5 mb-10"
          v-for="item in gallery"
          :key="item.title"
        >
          <div class="w-full">
            <img :src="item.image" />
          </div>
          <h4 class="text-white text-xl font-bold">
            <span class="font-black text-red-700">{{ item.title }} </span>
            {{ item.type }}
          </h4>
          <p class="text-zinc-500 font-black text-xs">BULLKING EXHAUST</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GalleryGsap } from "@/gsap/home/main_product";
export default {
  name: "HomeGallery",
  props: {
    gallery: Array,
  },
  watch: {
    page_image_loaded() {
      this.page_image_loaded ? GalleryGsap(this.$refs.HomeGallery) : "";
    },
  },
  mounted() {
    this.page_image_loaded ? GalleryGsap(this.$refs.HomeGallery) : "";
  },
};
</script>