import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/order_create/:id/:type',
    name: 'order_create',
    component: () => import( /* webpackChunkName: "order_create" */ "../views/OrderCreate.vue")
  },
  {
    path: '/error_page',
    name: 'error_page',
    component: () => import('../views/404Page.vue')
  },
  {
    path: '*',
    redirect: '/error_page'
  },
  {
    path: '/index.html',
    redirect: '/',
  },
  {
    path: '/index.php',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router