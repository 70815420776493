<template>
  <section
    ref="HomeEvent"
    id="HomeEvent"
    class="relative overflow-hidden w-full"
  >
    <div class="marquee_left">
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>
      <div class="whitespace-nowrap marquee_box">
        <p
          class="
            mr-5
            font-black
            md:text-4xl
            text-xl
            leading-none
            mb-2
            text-red-700
            inline-block
          "
          v-for="item in 50"
          :key="`text_${item}`"
        >
          LIMITED TIME SPECIAL
        </p>
      </div>
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>
    </div>

    <!-- <CountDown /> -->
    <div
      class="
        flex flex-wrap
        items-center
        pb-10
        w-full
        max-w-screen-xl
        mx-auto
        xl:px-0
        px-10
      "
    >
      <div class="md:w-1/2 w-full">
        <img
          class="w-full block product_image transform-gpu"
          src="/img/event.jpg"
        />
      </div>
      <div class="md:w-1/2 w-full text-white md:mt-0 mt-10 md:pl-10">
        <h3 class="text-3xl font-bold title">期間限定活動</h3>
        <h4 class="text-red-700 font-black sub_title">LIMITED TIME SPECIAL</h4>
        <hr class="border-red-700 mt-5 header_line" />
        <p class="mt-6 content">
          即日起訂購排氣管並且全額付款者<br />
          <span class="text-sm text-zinc-400"
            >(信用卡一次付清 / ATM付款 / 超商代碼繳費 / 貨到付款先行匯款)</span
          >
          <br />
          <br />
          皆可免費升級<span class="text-red-700 font-bold text-xl ml-1"
            >義義款彩鈦尾出口!!</span
          >
        </p>
      </div>
    </div>
    <div class="marquee_right">
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>

      <div class="whitespace-nowrap marquee_box">
        <p
          class="
            mr-5
            font-black
            md:text-4xl
            text-xl
            leading-none
            mb-2
            text-red-700
            inline-block
          "
          v-for="item in 50"
          :key="`text_${item}`"
        >
          LIMITED TIME SPECIAL
        </p>
      </div>
      <div class="whitespace-nowrap opacity-50 flash_line">
        <span
          class="
            md:mr-3
            mr-2
            md:w-2
            w-2
            md:h-5
            h-4
            bg-red-700
            inline-block
            transform
            skew-x-[30deg]
          "
          v-for="item in 500"
          :key="`text_${item}`"
        >
        </span>
      </div>
    </div>
  </section>
</template>

<script>
// import CountDown from "@/components/CountDown.vue";
import {
  MarqueeLeftGsap,
  MarqueeRightGsap,
  EventGsap,
} from "@/gsap/home/main_product";
export default {
  name: "HomeEvent",
  components: {
    // CountDown,
  },
  watch: {
    page_image_loaded() {
      let marquee_left = document.querySelector("#HomeEvent .marquee_left");
      let marquee_right = document.querySelector("#HomeEvent .marquee_right");
      if (this.page_image_loaded) {
        MarqueeLeftGsap(marquee_left);
        MarqueeRightGsap(marquee_right);
        EventGsap(this.$refs.HomeEvent);
      }
    },
  },
  mounted() {
    let marquee_left = document.querySelector("#HomeEvent .marquee_left");
    let marquee_right = document.querySelector("#HomeEvent .marquee_right");
    if (this.page_image_loaded) {
      MarqueeLeftGsap(marquee_left);
      MarqueeRightGsap(marquee_right);
      EventGsap(this.$refs.HomeEvent);
    }
  },
};
</script>