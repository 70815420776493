/* eslint-disable no-unused-vars */
import gsap from "gsap"

export default {
    data() {
        return {
            loading_tl: null,
        }
    },
    methods: {
        LoadImage() {
            let image_loader = document.querySelector("#ImageLoader")
            image_loader.style.display = "flex"
            image_loader.style.opacity = 1
            //鎖住body
            document.body.style.overflow = 'hidden';

            //初始化
            this.$store.commit("SetImageLoaded", false)
            const images = gsap.utils.toArray('img');
            const loader = document.querySelector('.loader--text span');

            //進度條
            const updateProgress = (instance) => {
                loader.innerHTML = `${Math.round(instance.progressedCount * 100 / images.length)}`
            };

            //完成事件
            const showDemo = () => {
                //復原body
                document.body.style.overflow = 'auto';
                window.scrollTo(0, 0);

                //設定讀取完成
                this.$store.commit("SetImageLoaded", true)
                console.log("all images have been loaded!")

                //關閉讀取畫面
                let image_loader = document.querySelector("#ImageLoader")
                let time_line = gsap.timeline()
                time_line.fromTo(image_loader, {
                    opacity: 1
                }, {
                    opacity: 0,
                }, 0)
                time_line.set(image_loader, {
                    display: "none",
                }, 1)
            }

            window.imagesLoaded(images).on('progress', updateProgress).on('always', showDemo);
        },
        ResetLoadImage() {
            let image_loader = document.querySelector("#ImageLoader")
            let time_line = gsap.timeline()
            time_line.set(image_loader, {
                display: "flex",
            })
            time_line.set(image_loader, {
                x: "0",
            })
            time_line.fromTo(image_loader, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0
            })
        },
    },
    computed: {
        page_image_loaded() {
            return this.$store.state.page_image_loaded
        }
    }
}