<template>
  <section id="HomeCover" class="relative">
    <div class="w-full">
      <img
        class="w-full h-full object-cover md:block hidden"
        :src="cover_data.pc_image"
      />
      <img
        class="w-full h-full object-cover md:hidden block"
        :src="cover_data.mb_image"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeCover",
  props: {
    cover_data: {
      require: Object,
    },
  },
};
</script>