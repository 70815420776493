<template>
  <footer id="MainFooter" class="w-full relative">
    <div class="w-full bg-red-700">
      <div
        class="
          w-full
          max-w-screen-xl
          mx-auto
          xl:px-0
          px-10
          py-4
          flex
          items-center
          justify-center
        "
      >
        <p class="text-white text-sm font-bold">
          有任何問題嗎?歡迎至
          <a
            href="https://www.facebook.com/YongXinExhaust"
            target="_blank"
            class="text-black underline text-sm"
            >粉絲專頁</a
          >
          與我們聯絡。
        </p>
      </div>
    </div>

    <div class="w-full bg-black">
      <div
        class="
          w-full
          max-w-screen-xl
          mx-auto
          xl:px-0
          px-10
          py-4
          flex
          items-center
          md:flex-row
          flex-col
          justify-center
        "
      >
        <img src="/img/logo.png" class="md:pr-5" />
        <div
          class="
            text-white
            md:pl-5 md:text-left md:mt-0
            mt-5
            text-center text-sm
            leading-relaxed
          "
        >
          <p>TEL：04-2471-9799</p>
          <p>FAX：04-2471-9499</p>
          <p>營業時間：08:00~18:00</p>
        </div>
      </div>
    </div>

    <div class="w-full bg-black border-t border-red-700">
      <div
        class="
          w-full
          max-w-screen-xl
          mx-auto
          xl:px-0
          px-10
          py-4
          text-center text-white text-xs
        "
      >
        <p>
          Copyright © 2014-2020 Bullking Inc. 著作權所有，並保留一切權利。<a
            class="text-red-700"
            href="https://www.bullking-exhaust.com/ship_doc"
            target="_blank"
            >退/換貨政策</a
          >
          ，網頁設計：<a
            class="text-red-700"
            href="https://www.yongxin-design.com/"
            target="_blank"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>