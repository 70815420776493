<template>
  <section id="HomeWarning" class="relative">
    <div class="w-full">
      <div class="w-full max-w-screen-xl mx-auto xl:px-0 px-10">
        <img src="/img/warning_cover.jpg" />
      </div>

      <div class="py-10 flex flex-col items-center bg-cover">
        <div class="w-full max-w-screen-xl mx-auto xl:px-0 px-10">
          <h3 class="text-white font-bold sm:text-4xl text-3xl">
            訂購義義聯名款排氣管注意事項
          </h3>
          <p class="font-black md:text-lg text-red-700 mt-1">PRECAUTIONS</p>
          <hr class="border-red-700 my-5" />
          <p class="text-white inline-block text-sm leading-loose">
            ．禁止於深夜時段或民宅處住所造成排氣管噪音．禁止於一般道路上競速，影響他人安全<br />
            ．請先確認自己愛車的引擎設定以及相對應的排氣管設定，如確認後開始製作將無法進行更改<br />
            ．如不太知道自己愛車的設定需搭配哪款排氣管設定，可利用「Bullking牛王競技部品」的粉絲專頁進行詢問，都將有專員為您服務<br />
            ．排氣管確認訂單後須收件，如惡意棄單Bullking牛王排氣管將會採取法律途徑<br />
            ．限量優惠活動期間之優惠，Bullking牛王競技部品以及義義都將保有一切解釋權力，不得異議<br />
            ．排氣管運輸費用採額外計價，因全台的運費標準較不統一，統一於訂購商品後致電告知
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeWarning',
};
</script>
