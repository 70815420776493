<template>
  <div id="app" class="bg-black w-full h-full min-h-screen overflow-x-hidden">
    <MainHeader />
    <ImageLoader />
    <MainLoading />
    <router-view />
    <MainFooter />
  </div>
</template>

<script>
import MainLoading from "@/components/MainLoading.vue";
import ImageLoader from "./components/ImageLoader.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
export default {
  name: "AppPage",
  components: {
    MainLoading,
    ImageLoader,
    MainHeader,
    MainFooter,
  },
};
</script>

<style src="@/assets/css/output.css"></style>