<template>
  <section id="HomePromote" class="relative md:py-32 py-10 z-10">
    <div class="flex md:flex-row flex-col md:items-stretch">
      <div class="md:w-1/2 w-full">
        <div class="md:hidden block mb-5">
          <h4
            class="text-white lg:text-5xl md:text-4xl text-3xl font-bold mb-1"
          >
            義哥推薦，絕對品質
          </h4>
          <p class="font-black md:text-lg text-red-700 mb-3">HIGH QUALITY</p>
          <hr class="border-red-700" />
        </div>
        <img src="/img/promote/DSC04632.jpg" />
      </div>
      <div class="md:w-1/2 w-full flex flex-col full">
        <div class="flex-1 md:block hidden">
          <h4 class="text-white lg:text-5xl text-4xl font-bold mb-1">
            義哥推薦，絕對品質
          </h4>
          <p class="font-black text-lg text-red-700 mb-3">HIGH QUALITY</p>
          <hr class="border-red-700" />
        </div>
        <div class="flex items-stretch flex-wrap">
          <div class="w-1/2 md:p-5">
            <img class="block" src="/img/promote/DSC04666.jpg" />
          </div>
          <div class="w-1/2 md:p-5">
            <img class="block" src="/img/promote/DSC04657.jpg" />
          </div>
          <div class="w-1/2 md:p-5">
            <img class="block" src="/img/promote/DSC04660.jpg" />
          </div>
          <div class="w-1/2 md:p-5">
            <img class="block" src="/img/promote/DSC04642.jpg" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomePromote",
};
</script>